import React from "react"
import { Link } from "gatsby"
import PopupLive from "../../components/popupLiveSurprise"
import PopupPodcast from "../../components/popupPodcast"
import GoodFridayLive from "../../components/popupGoodFriday"
import Layout from "../../components/layoutSurprise"
import SEO from "../../components/seo"
import EasterLive from "../../components/popupEaster"
import SermonSeriesSurprise from "../../components/sermonSeriesSurprise"
import UpcomingEvents from "../../components/eventsSurprise"
import MeetThePastorSurprise from "../../components/meetthePastorSurprise"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <SermonSeriesSurprise />
    <UpcomingEvents />
    <MeetThePastorSurprise />
  </Layout>
)

export default IndexPage
